import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Subscribe = () => (
  <Layout>
    <SEO title="Subscribe" />
    <div className="container">
      <h1>Subscribe</h1>
      <p>
      Subscribe to our newsletter:
      </p>
    </div>
  </Layout>
)

export default Subscribe
